"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EventBusMessage = void 0;
const typebox_1 = require("@sinclair/typebox");
const asset_management_messages_1 = require("./asset-management-messages");
const domain_management_1 = require("./domain-management");
const email_1 = require("./email");
const show_publish_service_1 = require("./show-publish-service");
const site_publish_service_1 = require("./site-publish-service");
/**
 * Union of any messages produced to or consumed from the Event Bus by
 * `@backstage/messaging-api`.
 */
exports.EventBusMessage = typebox_1.Type.Union([
    ...asset_management_messages_1.AssetManagementEvent.anyOf,
    ...domain_management_1.DomainManagementMessage.anyOf,
    ...show_publish_service_1.ShowPublishServiceMessage.anyOf,
    ...site_publish_service_1.SitePublishServiceMessage.anyOf,
    email_1.EmailMessageEvent,
]);
